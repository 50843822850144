<template>
  <b-sidebar
    id="view-user-sidebar"
    :visible="isViewInterviewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-"
    width="40%"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-view-interview-sidebar-active', val)"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">View Feedback</h5>
        <!-- <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        /> -->
      </div>

      <!-- BODY -->
      <!-- Form -->
      <b-form autocomplete="off" id="resume-form" class="p-2">
        <b-form-group label="Your Overall Recommendation">
          <b-form-radio-group
            v-model="userData.overall_rating"
            buttons
            button-variant="outline-primary"
            class="mx-n1"
            size="sm"
          >
            <template v-for="option in options">
              <b-form-radio
                :value="option.value"
                :key="option.text"
                :class="`rounded-pill ml-1 ` + `${option.color}`"
              >
                <div class="d-flex text-truncate align-items-center">
                  <feather-icon
                    class="cursor-pointer"
                    :icon="option.icon"
                    size="16"
                  />
                  {{ option.text }}
                </div>
              </b-form-radio>
            </template>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label="Overall Comments" label-for="review">
          {{ userData.review }}
        </b-form-group>

        <b-form-group v-for="(item, index) in customFields" :key="item.id">
          <b-form-group
            v-if="item.type == 'number'"
            :label="item.label"
            label-for="input-1"
          >
            <b-form-input
              :id="item.name"
              :name="`custom_fields_data[${item.name}-${item.id}]`"
              :value="userData.get_customfields_data['field_' + item.id]"
              :placeholder="item.label"
              type="number"
            />
          </b-form-group>

          <b-form-group
            v-if="item.type == 'text'"
            :label="item.label"
            label-for="input-1"
          >
            <b-form-input
              :id="item.name"
              :name="`custom_fields_data[${item.name}-${item.id}]`"
              :value="userData.get_customfields_data['field_' + item.id]"
              :placeholder="item.label"
              type="text"
              readonly
            />
          </b-form-group>

          <b-form-group
            v-if="item.type == 'rating'"
            :label="item.label"
            label-for="input-1"
          >
            <div class="d-flex align-items-center">
              <star-rating
                v-model="userData.get_customfields_data['field_' + item.id]"
                :show-rating="false"
                :star-size="30"
                :read-only="true"
              ></star-rating>
              <span
                v-if="userData.get_customfields_data['field_' + item.id]"
                :class="`rating-btn btn ${
                  ratingdescription[
                    userData.get_customfields_data['field_' + item.id]
                  ].class
                }`"
              >
                {{
                  ratingdescription[
                    userData.get_customfields_data["field_" + item.id]
                  ].text
                }}</span
              >
              <input
                type="hidden"
                v-model="userData.get_customfields_data['field_' + item.id]"
                :name="`custom_fields_data[${item.name}-${item.id}]`"
              />
            </div>

            <!-- <b-form-rating
                  icon-clear="slash-circle"
                  v-model="userData.reading_writing_rating"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="userData.get_customfields_data['field_' + item.id]"
                  show-clear
                  show-value
                ></b-form-rating> -->
          </b-form-group>

          <b-form-group
            v-if="item.type == 'password'"
            :label="item.label"
            label-for="input-1"
          >
            <b-form-input
              :id="item.name"
              :name="`custom_fields_data[${item.name}-${item.id}]`"
              :value="userData.get_customfields_data['field_' + item.id]"
              :placeholder="item.label"
              type="password"
            />
          </b-form-group>

          <b-form-group
            v-if="item.type == 'textarea'"
            :label="item.label"
            label-for="input-1"
          >
            {{ userData.get_customfields_data["field_" + item.id] }}
          </b-form-group>
          <b-form-group
            v-if="item.type == 'date'"
            :label="item.label"
            label-for="input-1"
          >
            <flat-pickr
              :config="{ dateFormat: 'Y-m-d' }"
              :name="`custom_fields_data[${item.name}-${item.id}]`"
              :value="userData.get_customfields_data['field_' + item.id]"
              class="form-control"
              placeholder="Select Date.."
            />
          </b-form-group>

          <b-form-group
            v-if="item.type == 'textarea_rating' && false"
            label=""
            label-for="input-1"
          >
            <div class="d-flex align-items-center">
              <label for="rating-inline">{{ item.label }}</label>
              <b-form-rating
                id="rating-inline"
                icon-clear="slash-circle"
                :model="`${item.name}_rating`"
                :name="`custom_fields_data[${item.name}_rating-${item.id}]`"
                show-clear
                class="mb-1 ml-auto mr-0"
                inline
                show-value
              ></b-form-rating>
            </div>
            <b-form-textarea
              id="review"
              :model="`${item.name}_note`"
              :name="`custom_fields_data[${item.name}_note-${item.id}]`"
              trim
              placeholder="Note"
              plaintext
            />
          </b-form-group>
        </b-form-group>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormCheckbox,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import interviewStoreModule from "../../interviewStoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeedBackAdd from "./FeedBackAdd.vue";
import StarRating from "vue-star-rating";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    FeedBackAdd,
    StarRating,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "isViewInterviewSidebarActive",
    event: "update:is-view-interview-sidebar-active",
  },
  props: {
    isViewInterviewSidebarActive: {
      type: Boolean,
      required: true,
    },
    interviewId: {
      type: String,
      required: false,
      default: "",
    },
    userData: {
      type: Object,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      is_loading: false,
      options: [
        {
          text: "Strongly Hire",
          value: "Strongly Hire",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
        },
        {
          text: "Hire",
          value: "Hire",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
        },
        {
          text: "Hold",
          value: "Hold",
          color: "btn-outline-primary",
          icon: "PauseIcon",
        },
        {
          text: "No Hire",
          value: "No Hire",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
        },
        {
          text: "Strong No Hire",
          value: "Strong No Hire",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
        },
      ],
      criteria: [
        { label: "Technical Skills", value: "Technical Skills" },
        { label: "Cultural Fit", value: "Cultural Fit" },
        { label: "Reading & Writing", value: "Reading & Writing" },
        {
          label: "Business Understanding / Entrepreneurial Skills",
          value: "Business Understanding / Entrepreneurial Skills",
        },
      ],
      feedback: {
        overall_rating: "",
        review: "",
        highlight: "",
        technical_skills_rating: "",
        technical_skills_note: "",
        cultural_fit_rating: "",
        cultural_fit_note: "",
        reading_writing_rating: "",
        reading_writing_note: "",
        business_understanding_rating: "",
        business_understanding_note: "",
      },
      customFields: [],
      star: 2,
      maxstars: 5,
      hasresults: true,
      hasdescription: true,
      disabled: false,
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      starsize: "lg", //[xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
    };
  },
  setup() {
    const blankUserData = {
      status: false,
      review: "",
    };

    // const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      //   userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const USER_APP_STORE_MODULE_NAME = "app-interviews";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, interviewStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    return {
      // userData,
    };
  },
  created() {
    // this.feedback = this.userData;
    // console.log(this.props.userData);
    this.getCustomFields();
  },
  methods: {
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-interviews/getCustomFields")
        .then((res) => {
          if (res.data.success) {
            self.customFields = res.data.data;
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Custom Fields Getting Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

#view-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
</style>
